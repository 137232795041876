/* Flex Container Utilities */

html,
body {
  font-family: monospace;
}

.capitalize {
  text-transform: capitalize;
}

.w-1rem {
  width: 1rem;
}

.w-2rem {
  width: 2rem;
}

.w-3rem {
  width: 3rem;
}

.w-4rem {
  width: 4rem;
}

.w-5rem {
  width: 5rem;
}

.w-6rem {
  width: 6rem;
}

.w-7rem {
  width: 7rem;
}

.w-8rem {
  width: 8rem;
}

.w-9rem {
  width: 9rem;
}

.w-10rem {
  width: 10rem;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Justify Content Utilities */
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

/* Align Items Utilities */
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* Align Content Utilities */
.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-stretch {
  align-content: stretch;
}

/* Flex Items Utilities */
.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

/* Margin Utilities */
.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.m-auto {
  margin: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 3rem;
}

/* Padding Utilities */
.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.inline-p-span {
  display: inline;
}

.clp {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.clp .category-name {
  width: auto;
  color: gold;
  display: flex;
  margin: 15px 0;
}

/* Button Classes */
.btn-primary {
  color: #fff;
  /* White text */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  font-weight: 600;
}

.overlay {
  background-color: #000;
  height: 100vh;
  left: 0;
  opacity: .6;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
  cursor: pointer;
}

.btn-secondary {
  background-color: #FFA500;
  /* Orange-Yellow */
  color: #fff;
  /* White text */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.btn-secondary:hover {
  background-color: #FFD700;
  /* Gold on hover */
}

.hidden {
  visibility: hidden;
}

#root footer {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  font-weight: 200;
  display: flex;
  flex-direction: column;
}

.minicart-container {
  bottom: 0;
  top: 0;
  left: unset;
  position: fixed;
  transform: unset;
  padding: 20px;
  min-width: 350px;
  right: 0;
  background-color: #000000f7;
  color: white;
  border: 1px solid#85858591;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  transition: 0.5s all ease-in-out;
  visibility: visible;
  z-index: 10;
}

.minicart-container.hidden {
  right: -350px;
  visibility: hidden;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.stripe-white {
  background-image: url(../assets/stripe-white-button.png);
  width: 144px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-font-smoothing: subpixel-antialiased;
}

.stripe-white-hover {
  background-image: url(../assets/stripe-white-button-hover.png);
}

.stripe-blue-hover {
  background-image: url(../assets/stripe-blue-button-hover.png);
}

@keyframes hue-rotation {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.block {
  display: block;
}

#root {
  background-color: #000;
  transition: all 0.5s ease-in-out;
  /* background-image: url(../assets/animated-background-2.webm);
  background-blend-mode: luminosity;
  position: fixed;
  overflow: scroll; */
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
}

.bg-vid {
  object-fit: cover;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 0;
  opacity: 0.9;
  position: fixed;
  mix-blend-mode: luminosity;
}

.twitch {
  filter: hue-rotate(276deg);
}

.rumble {
  /* #85c742 */
  filter: hue-rotate(102deg);
}

.youtube {
  filter: hue-rotate(19deg);
}

[class*="-logo"]:not(.xp-logo):not(.category-logo) {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  margin-bottom: 1.5rem;
  width: 100%;
  flex-direction: column;
}

.inline-block {
  display: inline-block;
}

.twitch-icon {
  background-image: url(../assets/twitch-icon.png);
}

.youtube-icon {
  background-image: url(../assets/youtube-icon.png);
}

.rumble-icon {
  background-image: url(../assets/rumble-icon.png);
}

.twitch-logo {
  background-image: url(../assets/twitch-logo.png);
}

.youtube-logo {
  background-image: url(../assets/youtube-logo.png);
}

.rumble-logo {
  background-image: url(../assets/rumble-logo.png);
}

/* 
0 degrees: Red
60 degrees: Yellow
120 degrees: Green
180 degrees: Cyan (a bluish-green color)
240 degrees: Blue
300 degrees: Magenta (a purplish-pink color)
360 degrees: Back to Red */

.search-container {
  @apply relative z-10 items-center mt-4 mb-4;
}

.socials-container a {
  max-width: 30px;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

.search-input {
  padding-left: 10px;
  padding-right: 10px;

  cursor: pointer;
  background-color: #0000007d;
  color: gold;
  border: 2px solid black;
  font-weight: 300;

  min-width: 200px;
}

.search-results {
  position: absolute;
  background-color: #000000f7;
  width: 100%;
  min-height: 36px;
  top: 100%;
  display: flex;
  color: gold;
  padding: 7px 0;
  margin-top: 1px;
  border: 1px solid #85858591;
}

.search-results a {
  color: gold;
  margin: 7px 15px 7px 14px;
}

.search-results a:hover {
  color: #fff;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-container>div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.bold {
  font-weight: 600;
}

.animated-background-shadow {
  height: 100vh;
  opacity: 0.9;
  width: 100vw;
  margin-left: 0;
  margin-right: 0;
}

.code-highlight {
  border: 1px solid #000000b8;
  background-color: #8f3d3d7a;
  display: inline-flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  padding: 5px 10px;
  color: #ffffff;
  width: fit-content;
}

.checkout-button {
  background-color: gold;
  color: #000;
  width: auto;
  display: block;
}

.input-container input,
.input-container select,
.input-container textarea,
.input-container button:not(.react-datepicker button) {
  width: 100%;
  padding: 0.25rem;
  margin-top: 1rem;
  cursor: pointer;
  min-width: 200px;

  background-color: #0000007d;
  border: 1px solid #b74949;
  color: gold;
  padding: 5px;
  margin-top: 10px;
  border: 2px solid #5b5b5b;
  font-weight: 300;
  margin-bottom: 20px;
}

button[type="submit"]:hover {
  background-color: #000;
}

.content-img {
  @apply mx-auto my-0;
  border: 1px solid #85858591
}

.content-wrapper {
  background-color: #000000ad;
  border: 0;
  color: white;
  position: relative;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.service-delivery {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.service-not-delivered {
  background-image: url('../assets/red-x.webp');
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 30px;
  background-position: 0px -2.5px;
  width: 30px;
  margin: 10px;
  opacity: 0.8;
}

.service-delivered {
  background-image: url('../assets/green-checkmark.png');
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 25px;
  background-position: top;
  margin: 10px 10px 10px 20px;
  opacity: 0.8;
}

.background-banner-1 {
  background-image: url('../assets/content_creator_auction.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-banner-2 {
  background-image: url('../assets/content_creator_network.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.service-not-delivered,
.service-delivered {
  opacity: 1
}

.nav-start {
  @apply items-center flex flex-col lg:flex-row lg:justify-between;
}

.nav-links {
  @apply flex w-full text-center justify-between flex-col lg:flex-row lg:mt-4;
}

.xp-nav {
  @apply flex-col items-center bg-transparent border-0 top-5 p-4 relative;
}

.stripe-button-container {
  margin-top: 15px;
}

.xp-listing {
  display: flex;
  justify-content: flex-start;
  margin-top: 1px;
  padding-left: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
  position: relative;
}

.responsive-scroll {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 100%;
  color: gold;
}

.test {
  max-width: 7rem;
  min-width: 7rem;
  padding: 0 20px;
  position: relative;
}

.test-2 {
  max-width: 9rem;
  min-width: 9rem;
  padding: 0 20px;
  position: relative;
}

.xp-listing div:not(.test):not(.test-2) {
  width: 100%;
}

.responsive-scroll>div:not(.test):not(.test-2) {
  min-width: 160px;
  padding: 0 15px;
  position: relative;
}

.filter-toolbar {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.filter-toolbar select,
.filter-toolbar input {
  max-width: unset;
}

.filter-toolbar>* {
  margin-right: 20px;
  margin-top: 40px;
}

.xp-listing:not(.toolbar) .responsive-scroll>div {
  /* border-top: 1px solid white; */
  min-height: 100%;
  align-items: center;
  display: flex;
  padding: 15px;
  background-color: #1e1e1ecc;
}

.toolbar .responsive-scroll>div {
  background-color: #000000e6;
}

.xp-listing:not(.toolbar) {
  color: gold;
}

.xp-listing.toolbar .responsive-scroll>div:after {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  border-top: 8px solid red;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.xp-listing.toolbar .responsive-scroll>div.sorted:after {
  top: calc(50% - 8px);
  rotate: 180deg;
}

a.xp-listing .responsive-scroll:hover {
  color: white;
}

/* .xp-listing:not(.toolbar):nth-of-type(odd) > div {
  padding: 15px 0;
}

.xp-listing:not(.toolbar):nth-of-type(even) > div {
  padding: 15px 0;
} */

h1 {
  padding-bottom: 1rem;
  text-align: center;
}

h2 {
  text-align: center;
}

.logo-container {
  @apply btn-primary underlined mt-0 mb-0;
}

div.game {
  margin-top: -21px;
  display: none;
}

div.game.flex {
  display: flex;
}

.game span {
  border: 2px solid #636363;
  padding: 5px 10px;
  background-color: #00000087;
  width: 100%;
  border-top: 0;
  cursor: pointer;
  color: gold;
}

.game span:hover {
  background-color: #3b3b3b69;
}

.xp-logo {
  @apply bg-no-repeat bg-center bg-contain block;
  height: 70px;
  transition: 0.2s ease-in-out;
  animation: glitch1 2.5s infinite;
  width: 300px;
  background-image: url('../assets/XPAuction.webp');
}

.xp-logo:hover {
  filter: saturate(5);
}

.underlined {
  background: linear-gradient(90deg, #e7d234, #e3a344), linear-gradient(90deg, #db3a68, #db3a68, #db3a68);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 3px, 0 3px;
  transition: background-size .4s;
  font-size: 16px;
}

.underlined:hover {
  background-size: 0 3px, 100% 3px;
}

/*Keyframes*/

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }

  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  27% {
    transform: none;
    opacity: 1;
  }

  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }

  35% {
    transform: none;
    opacity: 1;
  }

  52% {
    transform: none;
    opacity: 1;
  }

  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }

  50% {
    transform: none;
    opacity: 1;
  }

  72% {
    transform: none;
    opacity: 1;
  }

  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }

  80% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

#root h1 {
  font-size: 30px;
  padding: 20px 0 15px 0;
}

#root h2 {
  font-size: 22px;
  padding: 0 0 20px 0;
  margin-top: 20px;
}

.no-display {
  display: none;
}

p span {
  display: block;
  margin: 15px 0;
}

span {
  font-size: 14px;
}

label {
  color: #FFD700;
}

.span-link {
  color: #FFD700;
}

.guidance {
  list-style: auto;
  margin: 30px;
}

.guidance li {
  padding: 15px 0;
}

.m-0-auto {
  margin: 0 auto;
}

/* .pdp>div, .account>div, .pdp-container>div {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
  flex-wrap: wrap;
  align-items: flex-start;
} */

.no-listings {
  padding: 20px 0px 10px 15px;
}

.no-listings a:not(.span-link) {
  margin: 15px 0;
  color: #FFD700;
  display: block;
}

.homepage-banner-container .banner-image {
  height: auto;
  max-width: 100%;
  width: 50vw;
  filter: drop-shadow(12px -12px 6px black);
  margin: 14vw auto;
}

.cart-close {
  width: 25px;
  height: 25px;
  z-index: 99999;
  background-color: #ffffff;
  padding: 4px;
  cursor: pointer;
  margin-left: auto;
  filter: invert(1);
}

.incomplete {
  color: red;
}

.complete {
  color: lime;
}

.no-click {
  opacity: 0.5;
  pointer-events: none;
}

.bid-item {
  border: 1px solid #85858591;
  padding: 15px 0px 5px 15px;
}

.winning-bid {
  color: lime;
  margin: 0 0 20px 0;
}

.losing-bid {
  color: #ff6c00;
  margin: 0 0 20px 0;
}

.won {
  color: lime;
}

.lost {
  color: red;
}

.title-logo {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.white {
  color: #fff;
}

.category-logo {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}

.responsive-scroll {
  display: flex;
  align-items: center;
}

.toolbar>div {
  display: flex;
  align-items: center;
}

.xp-listing.btn-primary.toolbar>div:hover {
  color: gold;
}

.tooltip {
  display: flex;
  flex-direction: row !important;
}

.tooltip .code-highlight {
  margin: -5px 5px;
}

.layout-1-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.layout-2-col {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.col-1 {
  border-radius: 5px;
  padding: 20px;
  width: 100%;
}

.single {
  border-radius: 5px;
  padding: 20px;
  min-width: calc(100%);
}

.col-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: #0000004d;
  color: white;
  padding: 18px 20px;
}

.checkout button[type="submit"] {
  margin-top: -45px;
  /* For iframe */
  margin-left: auto;
}

[class*="auth-button"] {
  margin: 10px 0;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 250px;
  justify-content: center;
  cursor: pointer;
}

.checkout iframe {
  min-height: 72px;
}

.checkout-container {
  display: flex;
}

.checkout-review li {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.checkout-review h2 {
  width: auto;
  display: block;
  min-width: unset;
  max-width: unset;
  padding: 0 0 15px 0;
}

.checkout-review ul {
  width: 100%;
}

.checkout-review button {
  cursor: pointer;
  min-width: auto;
  color: gold;
  padding: 5px 0;
  margin-top: 10px;
  border: 2px solid black;
  font-weight: 300;
  width: 60px;
}

.checkout-review .item {
  background-color: #0000004d;
  padding: 20px;
  border: 1px solid #b3b3b3;
}

.checkout-review .item:not(:first-of-type) {
  border-top: 0;
}

[class*="background-banner"] p {
  background-color: #00000094;
}

@media screen and (min-width: 900px) {
  .game span {
    width: calc(100% - 20px);
  }
  
  .filter-toolbar {
    flex-direction: row;
  }

  .filter-toolbar select,
  .filter-toolbar input {
    max-width: 150px;
  }

  .background-banner-2 {
    background-position-y: -200px;
  }

  h1 {
    text-align: left;
  }

  .search-input {
    min-width: 400px;
  }

  .layout-2-col {
    flex-direction: row;
  }

  .col-1 {
    width: calc(50% - 36px);
  }

  .col-2 {
    width: 50%;
  }

  .input-container input,
  .input-container select,
  .input-container textarea,
  .input-container button:not(.react-datepicker button) {
    width: calc(100% - 20px);
    min-width: 300px;
  }

  .xp-nav {
    flex-direction: row;
  }

  .xp-logo {
    background-image: url('../assets/XP.webp');
    width: 105px;
  }
}

@media screen and (min-width: 1024px) {
  .xp-logo {
    background-image: url('../assets/XPAuction.webp');
    width: 300px;
  }
}

input[type="checkbox"] {
  width: 17px;
  min-width: unset;
  margin: 0px 15px 5px 0;
  height: 17px;
  vertical-align: middle;
}

.checkout-container .App {
  width: 100%;
}

.App button {
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  background-color: gold;
  color: black;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

button:hover {
  opacity: 0.8;
}




/* #root {
  display: flex;
  align-items: center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

#payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#payment-form button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#payment-form button:hover {
  filter: contrast(115%);
}

#payment-form button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    min-width: initial;
  }
}